@use 'sass:color';
// $primary-color: #706e6e; // headers, footers
// $secondary-color: #b8b8b8; // cards, background elements
// $accent-color: #999999; // highlighted text, buttons
// $button-color: #a4a2a2; // buttons, links
// $background-color: #efefef; // background
// $highlight-color: #d9d9d9; // Highlighted buttons, links
// $text-color: #000000; // Default text color

$primary-color: #1a1838; // headers, footers
$secondary-color: #282554; // cards, background elements
$accent-color: #483f8f; // highlighted text, buttons
$button-color: #6738e3; // buttons, links
$background-color: #0a1123; // background
$highlight-color: #8387c4; // Highlighted buttons, links
$text-color: #d9d9d9; // Default text color

// Generate utility classes for colors and hover states based on the color map
$colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'accent': $accent-color,
  'button': $button-color,
  'background': $background-color,
  'highlight': $highlight-color,
  'text': $text-color,
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }

  .border-#{$name} {
    border-color: $color;
  }

  .bg-#{$name}-hover:hover {
    background-color: color.mix($color, rgba(0, 0, 0, 0.5));
  }

  .text-#{$name}-hover:hover {
    color: color.mix($color, rgba(0, 0, 0, 0.5));
  }
}

// Add styles for every second child of offer_list
.offer_list > div:nth-child(2n),
.dlc_list > div:nth-child(2n) {
  background-color: $secondary-color;
  border-color: $highlight-color;
}
.offer_list > div:nth-child(2n):hover,
.dlc_list > div:nth-child(2n):hover {
  background-color: color.mix($highlight-color, rgba(0, 0, 0, 0.5));
}

/* CAROUSEL */

.carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top;
}

.carousel-item h2 {
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.carousel-fix-height {
  height: 580px;
}
