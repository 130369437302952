$qr-content-size: 200px;
$qr-padding: 5px;

.qr-container {
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $qr-padding;
  align-content: center;
  width: calc(#{$qr-content-size} + 2 * #{$qr-padding});
  height: calc(#{$qr-content-size} + 2 * #{$qr-padding});
}

.qr-content {
  width: 100%;
  position: absolute;
  top: 0;
  transform: scale(2.6);
  transform-origin: 0 0;
}

@mixin qr-styles($content-size, $padding, $scale) {
  .qr-container {
    padding: $padding;
    width: calc(#{$content-size} + 2 * #{$padding});
    height: calc(#{$content-size} + 2 * #{$padding});
  }
  .qr-content {
    transform: scale($scale);
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  @include qr-styles(300px, 5px, 4);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  @include qr-styles(300px, 5px, 4);
}

@media screen and (min-width: 1025px) {
  @include qr-styles(300px, 10px, 4.1);
}

.user-topup-qr-container {
  // background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  align-content: center;
  width: calc(220px + 2 * 5px);
  height: calc(200px + 2 * 5px);
}

.user-topup-qr-content {
  width: 100%;
  position: absolute;
  top: 0;
  transform: scale(4);
  transform-origin: 0 0;
}